import styled from 'styled-components';

export const StyledCommponents = styled.nav`
.btn-group{
  display:flex;
}
.btn{
  margin:0 5px;
}
  
`;
