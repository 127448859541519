import { StyledMenu } from './RegistrationApplicationFormContent.styled';
import { Button,TextField,Typography,Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import React, { useState, useEffect } from "react";
import {convertToJapaneseEra} from "../GeneralFunctions"

const RegistrationApplicationFormContent = ({data}) => {
  // const {era,year} =  convertToJapaneseEra(daat)

      return (
        <>
        <StyledMenu>
          
        <div className='main'>
          <div className='content'>
          <h2>登　記　申　請　書</h2>
          <p>　</p>
          <p>登録の目的　　建物{data.buildAction === "break" && <>滅失</>}登記</p>
          <p>　</p>
          <p>添付書類　　　　{(data.whoBreaker === "me" || data.whoBreaker === "unknown") && <>上申書</>}{(data.whoBreaker === "vendor") && <>建物滅失証明書</>}　代理権限証書　調査報告書</p>
          <p>　　　　　　　　　　{(data.isOwnerLife === 0) && <>相続証明書　</>}{(data.isDiffOwnerAddress === 1) && <>住宅証明情報</>}</p>
          {(data.whoBreaker === "vendor") && <p>　　　　　　　　　　{data.vendorCompanyName}（会社法人等番号　{data.vendorCompanyNumber}）</p>}
          {data.createAt !== undefined &&
          <p>{(new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })).format(new Date(data.createAt))}　　{(new Date(data.createAt)).getMonth()+1}月　　{(new Date(data.createAt)).getDate()}日申請　管轄エリア　御中</p>
          }
          <p>　</p>
          {(data.isOwnerLife === 0) &&
          <>
          <p>申　 請　 人　　被相続人　　住所　{data.ownerAddress}</p>
          <p>　　　　　　　　　　　　　　　　　 氏名　{data.ownerName}</p>
          <p>　　　　　　　　　  相続人　　　 住所　{data.heirAddress}</p>
          <p>　　　　　　　　　　　　　　　　　 氏名　{data.heirName}</p>
          </>
          }
          {(data.isDiffOwnerAddress === 1) &&
          <>
          <p>申　 請　 人　　住所　{data.ownerAddress}</p>
          <p>　　　　　　　　　  氏名　{data.ownerName}</p>
          </>
          }
          <p>代　 理　 人　　住所</p>
          <p>　　　　　　　　　  氏名</p>
          <p>　　　　　　　　　  連絡先の電話番号</p>
          <p>　</p>
          <table>
          <tbody>
            <tr>
                <td colspan="2" className='td-title'>不動産番号</td>
                <td colspan="5"></td>
            </tr>
            <tr>
                <td rowspan="7" className='td-title'><p>　</p>建<p>　</p>物<p>　</p>の<p>　</p>表<p>　</p>示<p>　</p></td>
                <td className='td-title'>所　　　在</td>
                <td colspan="5"><span className='td-content'>{data.registryAddress}</span></td>
            </tr>
            <tr>
                <td className='td-title'>家屋番号</td>
                <td colspan="5"><span className='td-content'>{data.registryNumber}</span></td>
            </tr>
            <tr>
                <td style={{fontSize:"80%"}} className='td-title'>主たる建物又は<br />付属建物</td>
                <td className='td-title'>➀種類</td>
                <td className='td-title'>②構造</td>
                <td  colspan="2" className='td-title'>③床面積</td>
                <td className='td-title'>④登記原因及び日付</td>
            </tr>
            <tr>
                <td>　</td>
                <td><span className='td-content'>{data.registryType}</span></td>
                <td><span className='td-content'>{data.registryStructure}</span></td>
                <td><span className='td-content'>{data.registryFloorSpace}</span></td>
                <td>　</td>
                {data.breakDate !== undefined &&
                <td style={{fontSize:"80%"}}><p className='td-content'>{(new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })).format(new Date(data.breakDate))}　{(new Date(data.breakDate)).getMonth()+1}月　{(new Date(data.breakDate)).getDate()}日取壊し</p>
                <p className='td-content'>{data.breakReason}</p>
                </td>
                }
                
            </tr>
            <tr>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
            </tr>
            <tr>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
            </tr>
            <tr>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
                <td>　</td>
            </tr>
          </tbody>
          </table>
          <p>　</p>
          　　　　受託第　　　　　　号　　　　　　土地家屋調査士　　　　　　　　　　　　職印
          </div>
          </div>
          </StyledMenu>
        </>
      );
    };
  export default RegistrationApplicationFormContent;