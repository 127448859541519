import { StyledMenu } from './BreakerCompanyInputForm.styled';
import { Button, TextField, Box } from '@mui/material';
import React, { useState } from "react";
import { StyledCommponents } from '../commponents.styled';
const BreakerSelectForm = ({ onNext, onBack }) => {

  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [error, setError] = useState('');

  const handleNext = (event) => {
    event.preventDefault();
    if (companyName === "" || companyNumber === "") {
      setError('会社名と法人番号を入力してください');
    }
    else {
      onNext({ companyName, companyNumber }, "breaker-vendor-company");
    }
  };

  return (
    <>
      <StyledCommponents>
        <div  style={{margin:"0 3%"}}>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <h3 style={{ fontSize: "150%;" }}>解体業者の会社名と法人番号を入力</h3>
            <div>
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="会社名"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="法人番号"
                value={companyNumber}
                onChange={e => setCompanyNumber(e.target.value)}
              />
            </div>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default BreakerSelectForm;