import { StyledMenu } from './PowerAttorneyContent2.styled';
import { Button,TextField,Typography,Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import React, { useState, useEffect } from "react";

const PowerAttorneyContent2 = ({data}) => {
      return (
        <>
        <StyledMenu>
        <div className='main'>
          <div className='content'>
          <div className='stamp-content'>
            認印
            <div class="dotted-circle"></div>
          </div>
          <h2>委　　任　　状</h2>
          <p>　</p>
          <p>　</p>
          <p>　　　　　　　　　　　　　　　住所</p>
          <p>　　　　　　　　　　　　　　　氏名</p>
          <p>　　　　　　　　　　　　　　　TEL</p>
          <p>　</p>
          <p>私は上記の者を代理人として定め下記の権限を委任します。</p>
          <p>　</p>
          <p>　</p>
          <p>住民票の交付申請の一切</p>
          <p>　</p>
          <p>　</p>
          <p>　</p>
          <p>　</p>
          {data.createAt !== undefined &&
          <p>{(new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })).format(new Date(data.createAt))}　　{(new Date(data.createAt)).getMonth()+1}月　　{(new Date(data.createAt)).getDate()}日</p>
          }
          <p>　</p>
          <p>　　　　住　所　{data.ownerAddress}</p>
          <p>　</p>
          <p>　　　　氏　名　{data.ownerName}</p>
          <div className='stamp-content2'>
            認印
            <div class="dotted-circle"></div>
          </div>
          </div>
          </div>
          </StyledMenu>
        </>
      );
    };
  export default PowerAttorneyContent2;