import styled from 'styled-components';

export const StyledMenu = styled.nav`

.main{
  background-color:#fff;
  font-family: 'MS PMincho', serif;
  line-height:1.7rem;
  width:100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
.content{
  margin:20% 15%;
}
h2{
  text-align:center;
  font-size:170%;
}
.stamp-content{
  position:absolute;
  right:15%;
  text-align:center;
  top:10%;
}
.stamp-content2{
  position:absolute;
  right:15%;
  text-align:center;
  bottom:8%;
}
.dotted-circle {
  margin:5px 0;
  width: 100px;
  height: 100px;
  border-radius: 50%; /* 50%で円形にする */
  border: 2px dotted black; /* 点線のスタイルと色を設定 */

}
  
`;
