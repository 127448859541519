import { StyledMenu } from './OwnerInfoInputForm.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";
import { StyledCommponents } from '../commponents.styled';

const OwnerInfoInputForm = ({ onNext, onBack }) => {

  const [ownerName, setOwnerName] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [heirName, setHeirName] = useState("");
  const [heirAddress, setHeirAddress] = useState("");
  const [error, setError] = useState('');

  const handleNext = (event) => {
    event.preventDefault();
    if (ownerName === "" || ownerAddress === "" || heirAddress === "" || heirName === "") {
      setError('名前と住所を入力してください');
    }
    else {
      onNext({ ownerAddress, ownerName, heirAddress, heirName }, "owner-heir-info");
    }
  };

  return (
    <>
      <StyledCommponents>
      <div  style={{margin:"0 3%"}}>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <div>
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="建物所有者の住所"
                value={ownerAddress}
                onChange={e => setOwnerAddress(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="建物所有者の名前"
                value={ownerName}
                onChange={e => setOwnerName(e.target.value)}
              />
            </div>
            <div>
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="相続人代表の住所"
                value={heirAddress}
                onChange={e => setHeirAddress(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="相続人代表の名前"
                value={heirName}
                onChange={e => setHeirName(e.target.value)}
              />
            </div>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default OwnerInfoInputForm;