import { StyledMenu } from './ApplicantInfoForm.styled';
import { Button, TextField, Box  } from '@mui/material';
import React, { useState  } from "react";
import { StyledCommponents } from '../commponents.styled';
const ApplicantInfoForm = ({ onNext, onBack }) => {

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState('');

  const handleNext = (event) => {
    event.preventDefault();
    if (name === "" || mail === "" || phone === "") {
      setError('申込者情報を入力してください');
    }
    else {
      onNext({ name, mail, phone }, "Applicant-info");
    }
  };

  return (
    <>
      <StyledCommponents>
      <div  style={{margin:"0 3%"}}>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <h3 style={{ fontSize: "150%;" }}>申込者情報の入力</h3>
            <div>
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="名前"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="メールアドレス"
                value={mail}
                onChange={e => setMail(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="電話番号"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  確認へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default ApplicantInfoForm;