import { StyledMenu } from './ConfirmPage.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";
import { StyledCommponents } from '../commponents.styled';


const RegistrationRecordInputForm = ({ dataArray, onBack, onRegister }) => {
  const handleRegister = (event) => {
    event.preventDefault();
    onRegister();
  };
  return (
    <>
      <StyledCommponents>
        <StyledMenu>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleRegister}>
            <div  style={{margin:"0 3%"}}>
              {dataArray.map((data) => (
                <div key={data.key}>
                  {data.key === 'estate' && (
                    <div className='section'>
                      <div className='section-title'>・土地か建物か</div>
                      <div className="value-title">
                        {data.value}
                      </div>
                    </div>
                  )}
                  {data.key === 'build-action' && (
                    <div className='section'>
                      <div className='section-title'>・建物をどうしたか</div>
                      <div className="value-title">
                        {data.value === 'new' && <>新築した</>}
                        {data.value === 'up' && <>増築した</>}
                        {data.value === 'other' && <>倉庫等を建てた</>}
                        {data.value === 'break' && <>建物を壊した</>}
                      </div>
                    </div>
                  )}
                  {data.key === 'who-breaker' && (
                    <div className='section'>
                      <div className='section-title'>・誰が壊したのか</div>
                      <div className="value-title">
                        {data.value === 'me' && <>自分</>}
                        {data.value === 'unknown' && <>不明</>}
                        {data.value === 'vendor' && <>解体業者</>}
                      </div>
                    </div>
                  )}
                  {data.key === 'breaker-vendor-company' && (
                    <div className='section'>
                      <div className='section-title'>・解体業者の会社名と法人番号</div>
                      <div className="value-title">
                        <div>会社名：{data.value.companyName}</div>
                        <div>法人番号：{data.value.companyNumber}</div>
                      </div>
                    </div>
                  )}
                  {data.key === 'life' && (
                    <div className='section'>
                      <div className='section-title'>・建物所有者（登記簿記録）の生死状況</div>
                      <div className="value-title">
                        {data.value === 'live' && <>生</>}
                        {data.value === 'death' && <>死</>}
                      </div>
                    </div>
                  )}
                  {data.key === 'owner-heir-info' && (
                    <div className='section'>
                      <div className='section-title'>・建物所有者と相続人の名前と住所</div>
                      <div className="value-title">
                        <div>建物所有者の住所：{data.value.ownerAddress}</div>
                        <div>建物所有者の名前：{data.value.ownerName}</div>
                        <div>相続人代表の住所：{data.value.heirAddress}</div>
                        <div>相続人代表の名前：{data.value.heirName}</div>
                      </div>
                    </div>
                  )}
                  {data.key === 'is-diff-address' && (
                    <div className='section'>
                      <div className='section-title'>・建物所有者の登記簿(謄本)と相違があるか</div>
                      <div className="value-title">
                        {data.value === 'true' && <>あり</>}
                        {data.value === 'false' && <>なし</>}
                      </div>
                    </div>
                  )}
                  {data.key === 'owner-now-info' && (
                    <div className='section'>
                      <div className='section-title'>・建物所有者の名前と住所</div>
                      <div className="value-title">
                        建物所有者の住所：{data.value.ownerAddress}
                        建物所有者の名前：{data.value.ownerName}
                      </div>
                    </div>
                  )}
                  {data.key === 'break-date' && (
                    <div className='section'>
                      <div className='section-title'>・取り壊し日と取り壊し原因</div>
                      <div className="value-title">
                        <div>取り壊し日：{(new Date(data.value.breakDate)).getFullYear() + "年" + ((new Date(data.value.breakDate)).getMonth() + 1) + "月" + (new Date(data.value.breakDate)).getDate() + "日"}</div>
                        <div>原因：{data.value.reason}</div>
                      </div>
                    </div>
                  )}
                  {data.key === 'registration-info' && (
                    <div className='section'>
                      <div className='section-title'>・登記簿記録情報</div>
                      <div className="value-title">
                        <div>所在：{data.value.address}</div>
                        <div>家屋番号：{data.value.number}</div>
                        <div>種類：{data.value.type}</div>
                        <div>構造：{data.value.structure}</div>
                        <div>床面積：{data.value.floorSpace}</div>
                      </div>
                    </div>
                  )}
                  {data.key === 'Applicant-info' && (
                    <div className='section'>
                      <div className='section-title'>・申込者情報</div>
                      <div className="value-title">
                        <div>申込者名：{data.value.name}</div>
                        <div>申込者メールアドレス：{data.value.mail}</div>
                        <div>申込者電話番号：{data.value.phone}</div>
                      </div>
                    </div>
                  )}


                </div>
              ))}
            </div>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  登録
                </Button>
              </div>
            </div>
          </Box>

        </StyledMenu>
      </StyledCommponents>
    </>
  );
};
export default RegistrationRecordInputForm;