import { StyledMenu } from './WrittenReportContent.styled';
import { Button,TextField,Typography,Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import React, { useState, useEffect } from "react";

const WrittenReportContent = ({data}) => {
      return (
        <>
        <StyledMenu>
        <div className='main'>
          <div className='content'>
          <div className='stamp-content'>
            実印
            <div class="dotted-circle"></div>
          </div>
          <h2>上　　申　　書</h2>
          <p>　</p>
          <p>　</p>
          <p>　</p>
          <p>　今般、後記記載の建物滅失登記申請書に際し、現地にはこの登記建物はなく建物の滅失の状況を知る相続人もなく、原因日の特定をすることができません。</p>
          <p>　本申請が受理かつ処理されますようここに上申いたします。</p>
          <p>　</p>
          <p>　</p>
          <p>　</p>
          <p>不動産の表示</p>
          <p>　</p>
          <p>　　主たる建物</p>
          <p>　　　所　　　在　{data.registryAddress}</p>
          <p>　　　家屋番号　{data.registryNumber}</p>
          <p>　　　種　　　類　{data.registryType}</p>
          <p>　　　構　　　造　{data.registryStructure}</p>
          <p>　　　床<span style={{marginRight:"0.5rem"}}></span>面<span style={{marginRight:"0.5rem"}}></span>積　{data.registryFloorSpace}</p>
          {data.breakDate !== undefined &&
            <p>　　　建物滅失年月日　{(new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })).format(new Date(data.breakDate))}　{(new Date(data.breakDate)).getMonth()+1}月　{(new Date(data.breakDate)).getDate()}日</p>
          }
          <p>　</p>
          <p>　</p>
          {data.createAt !== undefined &&
          <p>{(new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })).format(new Date(data.createAt))}　　{(new Date(data.createAt)).getMonth()+1}月　　{(new Date(data.createAt)).getDate()}日</p>
          }
          <p>　</p>
          <p>　</p>
          <p>　　　　　　　　　住　　　所　{data.ownerAddress}</p>
          <p>　</p>
          <p>　　　　　　　　　氏　　　名　{data.ownerName}</p>
          <div className='stamp-content2'>
            実印
            <div class="dotted-circle"></div>
          </div>
          </div>
          </div>
          </StyledMenu>
        </>
      );
    };
  export default WrittenReportContent;