import { StyledMenu } from './BreakerSelectForm.styled';
import { Button,TextField,Typography,Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import React, { useState, useEffect } from "react";
import { StyledCommponents } from '../commponents.styled';

const BreakerSelectForm = ({onNext,onBack}) => {
    
  const [breaker,setBreaker] = useState(null);
  const [error, setError] = useState('');

      const handleBreaker = (value) =>{
        setBreaker(value);
      }

      const handleNext = (event) => {
        event.preventDefault(); 
        if(breaker=== null){
          setError('いずれかの項目を選択してください');
        }
        else{
        onNext(breaker,"who-breaker");
        }
      };
    
      return (
        <>
        <StyledCommponents>
        <div>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
          {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <h3 style={{fontSize:"120%"}}>誰が壊したのか？</h3>
            <FormControl component="fieldset">
                <RadioGroup  name="who-breaker" value={breaker} onChange={e => handleBreaker(e.target.value)}>
                  <FormControlLabel value="me" control={<Radio />} label="自分" />
                  <FormControlLabel value="unknown" control={<Radio />} label="不明" />
                  <FormControlLabel value="vendor" control={<Radio />} label="解体業者" />
                </RadioGroup>
            </FormControl>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{backgroundColor:'#282c34'}}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={()=>onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{backgroundColor:'#282c34'}}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
          </div>
          </StyledCommponents>
        </>
      );
    };
  export default BreakerSelectForm;