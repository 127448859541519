
// import '../commponents/default.css';
import { Typography, Button } from '@mui/material';
import React, { useState, useEffect } from "react";
import { RegistrationApplicationFormContent, WrittenReportContent, BuildingLossCertificateContent, PowerAttorneyContent, PowerAttorneyContent2 } from './commponents';
import { useNavigate } from 'react-router-dom';
// import html2pdf from 'html2pdf.js';



const handleRegistrationApplicationFormContentPDF = async() => {
  const html2pdf = await import('html2pdf.js');
  const content = document.getElementById('RegistrationApplicationFormContent'); // コンテンツの要素を取得
  if(content){
    content.style.display = 'block';
    html2pdf.default(content, {
      filename: '登記申請書.pdf',
    }).then(() => {
      content.style.display = 'none';
    });
  }
};

const handleWrittenReportContentPDF = async() => {
  const html2pdf = await import('html2pdf.js');
  const content = document.getElementById('WrittenReportContent');
  if(content){
    content.style.display = 'block';
    html2pdf.default(content, {
      filename: '上申書.pdf',
    }).then(() => {
      content.style.display = 'none';
    });
  }
}

const handleBuildingLossCertificateContentPDF = async() => {
  const html2pdf = await import('html2pdf.js');
  const content = document.getElementById('BuildingLossCertificateContent');
  if(content){
    content.style.display = 'block';
    html2pdf.default(content, {
      filename: '建物滅失証明書.pdf',
    }).then(() => {
      content.style.display = 'none';
    });
  }

}

const handlePowerAttorneyContentPDF = async() => {
  const html2pdf = await import('html2pdf.js');
  const content = document.getElementById('PowerAttorneyContent');
  if(content){
    content.style.display = 'block';
    html2pdf.default(content, {
      filename: '委任状.pdf',
    }).then(() => {
      content.style.display = 'none';
    });
  }
}

const handlePowerAttorneyContentPDF2 = async() => {
  const html2pdf = await import('html2pdf.js');
  const content = document.getElementById('PowerAttorneyContent2');
  if(content){
    content.style.display = 'block';
    html2pdf.default(content, {
      filename: '委任状2.pdf',
    }).then(() => {
      content.style.display = 'none';
    });
  }
}
// const handleRegistrationApplicationFormContentPDF = async() => {
//   // if (typeof window !== 'undefined') {
  
//   const content = document.getElementById('RegistrationApplicationFormContent'); // コンテンツの要素を取得
//   content.style.display = 'block';
//   // const htmlContent = content.innerHTML;
//   html2pdf.default(content, {
//     filename: '登記申請書.pdf',
//   }).then(() => {
//     // const htmlContent = content.innerHTML;
//     // console.log(htmlContent);
//     // mammoth.extractRawText({ html: htmlContent })
//     //   .then((result) => {
//     //     const blob = new Blob([result.value], { type: "application/msword" });
//         // const link = document.createElement("a");
//         // link.href = URL.createObjectURL(blob);
//         // link.download = "登記申請書.doc";
//         // link.click();
//       // });
//   //   content.style.display = 'none';
//   });

//   // または、HTML to Word
//   // const htmlContent = content.innerHTML;
//   // mammoth.extractRawText({ html: htmlContent })
//   //   .then((result) => {
//   //     const blob = new Blob([result.value], { type: "application/msword" });
//   //     const link = document.createElement("a");
//   //     link.href = URL.createObjectURL(blob);
//   //     link.download = "RegistrationForm.doc";
//   //     link.click();
//   //   });
// //   try {
// //     console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");
// //     const response = await fetch('/api/generate-pdf', {
// //         method: 'POST',
// //         headers: {
// //             'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify({htmlContent }),
// //     });

// //     if (response.ok) {
// //       console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaa",response);
// //       // await response.text().then(data => {
// //       //   console.log(data);
// //       // })
// //       // .catch(error => {
// //       //   console.log(error);
// //       // });
// //         const pdfData = await response.arrayBuffer();
// // console.log(pdfData);
// //         // PDFをダウンロード
// //         const blob = new Blob([pdfData], { type: 'application/pdf' });
// //         const link = document.createElement('a');
// //         link.href = window.URL.createObjectURL(blob);
// //         link.download = '登記申請書.pdf';
// //         link.click();
// //     } else {
// //         console.log('Error generating PDF:', response.statusText);
// //     }
// // } catch (error) {
// //     console.log('Error generating PDF:', error);
// // }
//   // }
// };



const Document = () => {
  
  const [register, setRegister] = useState([]);
  const router = useNavigate();
  const { id } = router.query;

  useEffect(() => {
    if (id) {
      fetch("/api/getRegisterInfoWithId.php", {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id }),
      })
        .then(response => {
          if (response.status == 200) {
          }
          return response.json();
        })
        .then(data => {
          if (data) {
            setRegister(data.results[0]);
          }
        })
        .catch(error => {
        });
    }
  }, []);



  return (
    <>
      {register &&
        <div className='center-content'>
          <div>
            <Typography style={{ fontWeight: 'bold', margin: '10px 0 20px', textAlign: 'center' }} component="h4" variant="h4">
              申請書ダウンロード
            </Typography>
            <div className='content'>
              <div id="RegistrationApplicationFormContent" style={{ display: "none" }}><RegistrationApplicationFormContent data={register} /></div>
              <div>
                <Button onClick={()=>handleRegistrationApplicationFormContentPDF()}>登記申請書</Button>
              </div>

              {(register.whoBreaker === "me" || register.whoBreaker === "unknown") &&
                <>
                  <div id="WrittenReportContent" style={{ display: "none" }}><WrittenReportContent data={register} /></div>
                  <div>
                    <Button onClick={()=>handleWrittenReportContentPDF()}>上申書</Button>
                  </div>
                </>
              }
              {(register.whoBreaker === "vendor") &&
                <>
                  <div id="BuildingLossCertificateContent" style={{ display: "none" }}><BuildingLossCertificateContent data={register} /></div>
                  <div>
                    <Button onClick={()=>handleBuildingLossCertificateContentPDF()}>建物滅失証明書</Button>
                  </div>
                </>
              }
              <div id="PowerAttorneyContent" style={{ display: "none" }}><PowerAttorneyContent data={register} /></div>
              <div>
                <Button onClick={()=>handlePowerAttorneyContentPDF()}>委任状</Button>
              </div>
              {(register.isDiffOwnerAddress === 1) &&
                <>
                  <div id="PowerAttorneyContent2" style={{ display: "none" }}><PowerAttorneyContent2 data={register} /></div>
                  <div>
                    <Button onClick={()=>handlePowerAttorneyContentPDF2()}>委任状2</Button>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default Document;