import '../src/commponents/default.css';
import { Typography } from '@mui/material';
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const getJapaneseDate = (originalDateString) => {
  const originalDate = new Date(originalDateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return originalDate.toLocaleDateString("ja-JP", options);
}

const Admin = () => {
  const history = useNavigate();

  const [registerArray, setRegisterArray] = useState([]);

  const handleMoveDocPage = (id) => {
    history.push(`/Document?id=${id}`);
  };

  useEffect(() => {
    // データの取得処理
    fetch("/api/getRegisterInfo.php", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({}),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.results);
        setRegisterArray(data.results);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className='center-content'>
        <div>
          <Typography style={{ fontWeight: 'bold', margin: '10px 0 20px', textAlign: 'center' }} component="h4" variant="h4">
            申請一覧
          </Typography>
          <div className="user-content">
            {registerArray.map((data, index) => (
              <div key={index}>
                <button className="user-item" onClick={() => handleMoveDocPage(data.id)}>
                  <div style={{ textAlign: "start" }}>申込者：{data.name}</div>
                  <div style={{ textAlign: "end" }}> 申請日：{getJapaneseDate(data.createAt)}</div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
