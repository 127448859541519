import { StyledMenu } from './PowerAttorneyContent.styled';
import { Button,TextField,Typography,Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import React, { useState, useEffect } from "react";
import {convertToJapaneseEra} from "../GeneralFunctions"

const PowerAttorneyContent = ({data}) => {
      return (
        <>
        <StyledMenu>
        <div className='main'>
          <div className='content'>
          <div className='stamp-content'>
            認印
            <div class="dotted-circle"></div>
          </div>
          <h2>委　　任　　状</h2>
          <p>　</p>
          <p>　</p>
          <p>　　　　　　　　　　　　　　　住所</p>
          <p>　　　　　　　　　　　　　　　氏名</p>
          <p>　　　　　　　　　　　　　　　TEL</p>
          <p>　</p>
          <p>私は上記の者を代理人として定め下記の権限を委任します。</p>
          <p>　</p>
          <p>不動産の表示</p>
          <p>　所　　　在　{data.registryAddress}</p>
          <p>　家屋番号　{data.registryNumber}</p>
          <p>　種　　　類　{data.registryType}</p>
          <p>　構　　　造　{data.registryStructure}</p>
          <p>　床<span style={{marginRight:"0.5rem"}}></span>面<span style={{marginRight:"0.5rem"}}></span>積　{data.registryFloorSpace}</p>
          <p>　</p>
          <p>　</p>
          <p>1．上記の建物に関する建物{data.buildAction === "break" && <>滅失</>}登記に関する件。</p>
          <p>2．登記申請書に添付した書類の原本還付請求と受領に関する件。</p>
          <p>3．登記申請書の書類補正のため申請書取下げに関する件。</p>
          <p>　</p>
          {data.createAt !== undefined &&
          <p>{(new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })).format(new Date(data.createAt))}　　{(new Date(data.createAt)).getMonth()+1}月　　{(new Date(data.createAt)).getDate()}日</p>
          }
          <p>　</p>
          <p>　　　　住　所　{data.ownerAddress}</p>
          <p>　</p>
          <p>　　　　氏　名　{data.ownerName}</p>
          <div className='stamp-content2'>
            認印
            <div class="dotted-circle"></div>
          </div>
          </div>
          </div>
          </StyledMenu>
        </>
      );
    };
  export default PowerAttorneyContent;