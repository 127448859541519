
import './commponents/default.css';
import { Typography } from '@mui/material';
import React, { useState, } from "react";
import { BuildActionSelectForm, EstateSelectForm, BreakerSelectForm, BreakerCompanyInputForm, LifeSelectForm, OwnerInfoInputForm, OwnerNowInfoInputForm, BuildBreakDateForm, RegistrationRecordInputForm, IsDiffOwnerAddressForm, ConfirmPage, ApplicantInfoForm } from './commponents';

let dataArray = [];
const ApplicationForm = () => {

  const steps = ['Step 1', 'Step 2', 'Step 3'];

  const [activeStep, setActiveStep] = useState(0);

  const [prevKey, setPrevKey] = useState(null);
  const [prevValue, setPrevValue] = useState(null);



  const handleNext = (value, key) => {

    setActiveStep(activeStep + 1);
    setPrevKey(key);
    setPrevValue(value);
    const existingIndex = dataArray.findIndex(item => item.key === key);
    if (existingIndex !== -1) {
      dataArray[existingIndex] = { key, value };
    } else {
      dataArray.push({ key, value });
    }
  };

  const handleBack = () => {
    const prevKeyCpy = prevKey;
    setActiveStep(activeStep - 1);
    const prevIndex = dataArray.findIndex(item => item.key === prevKeyCpy);
    if (prevIndex !== -1) {
      if (prevIndex === 0) {
        dataArray = [];
        setPrevKey(null);
        setPrevValue(null);
      }
      else {
        const { key, value } = dataArray[prevIndex - 1];
        setPrevKey(key);
        setPrevValue(value);
      }

    }

    dataArray = dataArray.filter(item => item.key !== prevKeyCpy);
  };

  const handleRegister = () => {
    fetch("/api/registerForm.php", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ dataArray }),
    })
      .then(response => {
        if (response.status == 200) {
          alert("登録しました");
          window.location.reload();
        }
        else {
          alert("登録に失敗しました");
          window.location.reload();
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        alert("登録に失敗しました");
        window.location.reload();
      });
  };

  return (
    <>
      <div className='center-content'>
        <div>
          {/* <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}
          <Typography style={{ fontWeight: 'bold', margin: '10px 0 20px', textAlign: 'center' }} component="h4" variant="h4">
            テストフォーム
          </Typography>

          {prevKey === null && <EstateSelectForm onNext={handleNext} />}
          {(prevKey === 'estate' && prevValue === "建物") && <BuildActionSelectForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'build-action' && prevValue === 'break') && <BreakerSelectForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'who-breaker' && prevValue === 'vendor') && <BreakerCompanyInputForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'who-breaker' && (prevValue === 'me' || prevValue === 'unknown')) && <LifeSelectForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'breaker-vendor-company') && <LifeSelectForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'life' && prevValue === 'death') && <OwnerInfoInputForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'life' && prevValue === 'live') && <IsDiffOwnerAddressForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'is-diff-address' && prevValue === "true") && <OwnerNowInfoInputForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'owner-heir-info' || prevKey === 'owner-now-info' || (prevKey === 'is-diff-address' && prevValue === "false")) && <BuildBreakDateForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'break-date') && <RegistrationRecordInputForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'registration-info') && <ApplicantInfoForm onNext={handleNext} onBack={handleBack} />}
          {(prevKey === 'Applicant-info') && <ConfirmPage dataArray={dataArray} onBack={handleBack} onRegister={handleRegister} />}


        </div>
      </div>
    </>
  );
};
export default ApplicationForm;