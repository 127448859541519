import { StyledMenu } from './BuildActionSelectForm.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";
import { StyledCommponents } from '../commponents.styled';

const BuildActionSelectForm = ({ onNext, onBack }) => {

  const [action, setAction] = useState(null);
  const [error, setError] = useState('');

  const handleActionChange = (value) => {
    setAction(value);
  }

  const handleNext = (event) => {
    event.preventDefault();
    if (action === null) {
      setError('いずれかの項目を選択してください');
    }
    else {
      onNext(action, "build-action");
    }
  };

  return (
    <>
      <StyledCommponents>
        <div>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <FormControl component="fieldset">
              <RadioGroup name="build-action" value={action} onChange={e => handleActionChange(e.target.value)}>
                <FormControlLabel value="new" control={<Radio />} label="新築した" />
                <FormControlLabel value="up" control={<Radio />} label="増築した" />
                <FormControlLabel value="other" control={<Radio />} label="倉庫等を建てた" />
                <FormControlLabel value="break" control={<Radio />} label="建物を壊した" />
              </RadioGroup>
            </FormControl>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default BuildActionSelectForm;