import styled from 'styled-components';

export const StyledMenu = styled.nav`

.main{
  background-color:#fff;
  font-family: 'MS PMincho', serif;
  line-height:1.7rem;
  width:100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
.content{
  margin:20% 15%;
}
h2{
  text-align:center;
  font-size:170%;
}

td{
  border:1px solid #000;
  vertical-align: middle;
}
.td-title{
  text-align:center;
}
.td-content{
  margin-left:5px;
}
table{
  width:100%;
  // border:3px double #000;
}
  
`;
