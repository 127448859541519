import { StyledMenu } from './BuildBreakDateForm.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";
// import { DatePicker } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import jaLocale from "date-fns/locale/ja";
import { StyledCommponents } from '../commponents.styled';


const BuildBreakDateForm = ({ onNext, onBack }) => {

  const [breakDate, setBreakDate] = useState(null);
  const [reason, setReason] = useState("");
  const [error, setError] = useState('');

  const handleBreakDate = (value) => {
    setBreakDate(value);
  }

  const handleNext = (event) => {
    event.preventDefault();
    if (breakDate === null || reason === "") {
      setError('取り壊し日と取り壊し原因を入力してください');
    }
    else {
      onNext({ breakDate, reason }, "break-date");
    }
  };

  return (
    <>
      <StyledCommponents>
      <div  style={{margin:"0 3%"}}>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <h3 style={{ fontSize: "150%;" }}>壊した日付・原因を入力</h3>
            <div style={{margin:"10px 0"}}>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
              <DatePicker
                style={{ margin: '15px 0' }}
                label="取り壊し日"
                name="breakDate"
                format='yyyy/MM/dd'
                value={breakDate}
                onChange={(newValue) => handleBreakDate(newValue)}
              />
            </LocalizationProvider> */}
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="原因"
                value={reason}
                onChange={e => setReason(e.target.value)}
              />
            </div>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default BuildBreakDateForm;