import { StyledMenu } from './RegistrationRecordInputForm.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";
import { StyledCommponents } from '../commponents.styled';

const RegistrationRecordInputForm = ({ onNext, onBack }) => {

  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [type, setType] = useState("");
  const [structure, setStructure] = useState("");
  const [floorSpace, setFloorSpace] = useState("");
  const [error, setError] = useState('');

  const handleNext = (event) => {
    event.preventDefault();
    if (address === "" || number === "" || type === "" || structure === "" || floorSpace === "") {
      setError('登記簿記録の情報を入力してください');
    }
    else {
      onNext({ address, number, type, structure, floorSpace }, "registration-info");
    }
  };

  return (
    <>
      <StyledCommponents>
        <div style={{margin:"0 3%"}}>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <h3 style={{ fontSize: "150%;" }}>登記簿記録情報記入欄</h3>
            <div>
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="所在"
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="家屋番号"
                value={number}
                onChange={e => setNumber(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="種類"
                value={type}
                onChange={e => setType(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="構造"
                value={structure}
                onChange={e => setStructure(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="床面積"
                value={floorSpace}
                onChange={e => setFloorSpace(e.target.value)}
              />
            </div>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default RegistrationRecordInputForm;