import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import ApplicationForm from './ApplicationForm';
import Document from './Document';
import Admin from './Admin';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/ApplicationForm" component={ApplicationForm} />
        {/* <Route path="/ApplicationForm" element={<ApplicationForm />} /> */}
        <Route path="/Admin" element={<Admin />} />
        <Route path="/Document" element={<Document />} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
