import { StyledMenu } from './LifeSelectForm.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";
import { StyledCommponents } from '../commponents.styled';

const LifeSelectForm = ({ onNext, onBack }) => {

  const [life, setLife] = useState(null);
  const [error, setError] = useState('');

  const handleLife = (value) => {
    setLife(value);
  }

  const handleNext = (event) => {
    event.preventDefault();
    if (life === null) {
      setError('いずれかの項目を選択してください');
    }
    else {
      onNext(life, "life");
    }
  };

  return (
    <>
      <StyledCommponents>
      <div  style={{margin:"0 3%"}}>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <h3 style={{ fontSize: "150%;" }}>建物所有者(登記簿記録)の生死状況を選択</h3>
            <FormControl component="fieldset">
              <RadioGroup name="life" value={life} onChange={e => handleLife(e.target.value)}>
                <FormControlLabel value="death" control={<Radio />} label="死" />
                <FormControlLabel value="live" control={<Radio />} label="生" />
              </RadioGroup>
            </FormControl>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default LifeSelectForm;