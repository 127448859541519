import { StyledMenu } from './EstateSelectForm.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";

const EstateSelectForm = ({ onNext }) => {

  const [estate, setEstate] = useState(null);
  const [error, setError] = useState('');

  const handleEstateChange = (value) => {
    setEstate(value);
  }

  const handleNext = (event) => {
    event.preventDefault();
    if (estate === null) {
      setError('いずれかの項目を選択してください');
    }
    else {
      onNext(estate, "estate");
    }

  };

  return (
    <>
      <div>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
          {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
          <FormControl component="fieldset">
            <RadioGroup name="estate" value={estate} onChange={e => handleEstateChange(e.target.value)}>
              <FormControlLabel value="土地" control={<Radio />} label="土地" />
              <FormControlLabel value="建物" control={<Radio />} label="建物" />
            </RadioGroup>
          </FormControl>
          <div>
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: '#282c34' }}
              sx={{ mt: 3, mb: 2 }}
            >
              次へ
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};
export default EstateSelectForm;