import { StyledMenu } from './IsDiffOwnerAddressForm.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";
import { StyledCommponents } from '../commponents.styled';

const IsDiffOwnerAddressForm = ({ onNext, onBack }) => {

  const [isDiff, setIsDiff] = useState(null);
  const [error, setError] = useState('');

  const handleNext = (event) => {
    event.preventDefault();
    if (isDiff === null) {
      setError('いずれかを選択してください');
    }
    else {
      onNext(isDiff, "is-diff-address");
    }
  };
  const handleIsDiff = (value) => {
    setIsDiff(value);
  }

  return (
    <>
      <StyledCommponents>
      <div  style={{margin:"0 3%"}}>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <h3 style={{ fontSize: "150%;" }}>建物所有者の登記簿(謄本)と相違があるか？</h3>
            <RadioGroup name="isDiffOwnerAddress" value={isDiff} onChange={e => handleIsDiff(e.target.value)}>
              <FormControlLabel value={true} control={<Radio />} label="あり" />
              <FormControlLabel value={false} control={<Radio />} label="なし" />
            </RadioGroup>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default IsDiffOwnerAddressForm;