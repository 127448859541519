import { StyledMenu } from './OwnerNowInfoInputForm.styled';
import { Button, TextField, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState, useEffect } from "react";
import { StyledCommponents } from '../commponents.styled';

const OwnerNowInfoInputForm = ({ onNext, onBack }) => {

  const [ownerName, setOwnerName] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [error, setError] = useState('');

  const handleNext = (event) => {
    event.preventDefault();
    if (ownerName === "" || ownerAddress === "") {
      setError('名前と住所を入力してください');
    }
    else {
      onNext({ ownerAddress, ownerName }, "owner-now-info");
    }
  };

  return (
    <>
      <StyledCommponents>
      <div  style={{margin:"0 3%"}}>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleNext}>
            {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
            <h3 style={{ fontSize: "150%;" }}>建物所有者の住所と名前を入力</h3>
            <div>
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="建物所有者の住所"
                value={ownerAddress}
                onChange={e => setOwnerAddress(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="建物所有者の名前"
                value={ownerName}
                onChange={e => setOwnerName(e.target.value)}
              />
            </div>
            <div className='btn-group'>
              <div className='btn'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onBack()}
                >
                  戻る
                </Button>
              </div>
              <div className='btn'>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#282c34' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  次へ
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </StyledCommponents>
    </>
  );
};
export default OwnerNowInfoInputForm;