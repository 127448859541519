import { StyledMenu } from './BuildingLossCertificateContent.styled';
import { Button,TextField,Typography,Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import React, { useState, useEffect } from "react";

const BuildingLossCertificateContent = ({data}) => {
      return (
        <>
        <StyledMenu>
        <div className='main'>
          <div className='content'>
          <div className='stamp-content'>
            実印
            <div class="dotted-circle"></div>
          </div>
          <h2>建　物　滅　失　証　明　書</h2>
          <p>　</p>
          <p>　</p>
          <p>　</p>
          <p>建物の所在地番</p>
          <p>　{data.registryAddress}</p>
          <p>家屋番号</p>
          <p>　{data.registryNumber}</p>
          <p>種類</p>
          <p>　{data.registryType}</p>
          <p>構造</p>
          <p>　{data.registryStructure}</p>
          <p>床面積</p>
          <p>　{data.registryFloorSpace}</p>
          <p>原因及び日付</p>
          {data.breakDate !== undefined &&
            <p>　{(new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })).format(new Date(data.breakDate))}　{(new Date(data.breakDate)).getMonth()+1}月　{(new Date(data.breakDate)).getDate()}日</p>
          }
          <p>　{data.breakReason}</p>
          <p>所有者</p>
          <p>　{data.ownerAddress}</p>
          <p>　{data.ownerName}</p>
          <p>　</p>
          <p>　以上のとおり建物を取り壊したことを証明します。</p>
          <p>　</p>
          {data.createAt !== undefined &&
          <p>{(new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })).format(new Date(data.createAt))}　　{(new Date(data.createAt)).getMonth()+1}月　　{(new Date(data.createAt)).getDate()}日</p>
          }
          <p>　</p>
          <p>　　　　住　所　{data.ownerAddress}</p>
          <p>　</p>
          <p>　　　　氏　名　{data.ownerName}</p>
          <div className='stamp-content2'>
            実印
            <div class="dotted-circle"></div>
          </div>
          </div>
          </div>
          </StyledMenu>
        </>
      );
    };
  export default BuildingLossCertificateContent;