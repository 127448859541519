import styled from 'styled-components';

export const StyledMenu = styled.nav`
.section{
  margin :10px 0;
}
.section-title{

}
.value-title{
  margin-left:10px;
}
  
`;
